const { NODE_ENV, VUE_APP_ELECTRON_ORIGIN_BASE_URL, VUE_APP_BASE_API, VUE_APP_ENV } = process.env
const IS_DEV = NODE_ENV == 'development'
// 接口地址需要 转换的服务
const NEED_REPLACE_SERVICE = ['/boss/', '/pro/']

// 通过 host 匹配国家
const countryHostMap = [
  { host: 'com.vn', value: 'VN' },
  { host: 'co.th', value: 'TH' },
  { host: 'co.id', value: 'ID' }
]
const hostSuffixMap = {
  VN: 'com.vn',
  TH: 'co.th',
  ID: 'co.id'
}
const countryHost = countryHostMap.find((item) => window.location.host.includes(item.host)) || {}
const country = countryHost.value || 'ID'
const hostSuffix = hostSuffixMap[country]

const SERVICE_BASE_URL_MAP = {
  development: {
    '/sso/': `https://sso-uat.fuse.${hostSuffix}/api`,
    '/auth/': `https://sso-uat.fuse.${hostSuffix}/api`,
    '/pro/': `https://ptr-uat.fuse.${hostSuffix}/api`,
    '/local/': `https://ptr-uat.fuse.${hostSuffix}/api`,
    '/claim/': `https://ptr-uat.fuse.${hostSuffix}/api`,
    // '/oss/': `https://tmp-uat.fuse.${hostSuffix}/`,
    '/oms/': `https://tmp-uat.fuse.${hostSuffix}/`,
    '/bpm/': `https://tmp-uat.fuse.${hostSuffix}/`,
    // '/message/': `https://tmp-uat.fuse.${hostSuffix}/`,
    // '/sys/': `https://tmp-uat.fuse.${hostSuffix}/`,
    '/dps/': `https://tmp-uat.fuse.${hostSuffix}/`,
    // '/pfm/': `https://tmp-uat.fuse.${hostSuffix}/`,
    '/boss/': `https://boss-uat.fuse.${hostSuffix}/api/`
  },
  dev: {
    '/sso/': `https://sso-dev.fusenano.com/api`,
    '/auth/': `https://sso-dev.fusenano.com/api`,
    '/pro/': `https://ptr-dev.fusenano.com/api`,
    '/local/': `https://ptr-dev.fusenano.com/api`,
    '/claim/': `https://ptr-dev.fusenano.com/api`,
    // '/oss/': `https://tmp-dev.fusenano.com/`,
    '/oms/': `https://tmp-dev.fusenano.com/`,
    '/bpm/': `https://tmp-dev.fusenano.com/`,
    // '/message/': `https://tmp-dev.fusenano.com/`,
    // '/sys/': `https://tmp-dev.fusenano.com/`,
    '/dps/': `https://tmp-dev.fusenano.com/`,
    // '/pfm/': `https://tmp-dev.fusenano.com/`,
    '/boss/': `https://boss-dev.fusenano.com/api/`
  },
  sit: {
    '/sso/': `https://sso-sit.fuse.com.vn/api`,
    '/auth/': `https://sso-sit.fuse.com.vn/api`,
    '/pro/': `https://ptr-sit.fuse.com.vn/api`,
    '/local/': `https://ptr-sit.fuse.com.vn/api`,
    '/claim/': `https://ptr-sit.fuse.com.vn/api`,
    // '/oss/': `https://tmp-sit.fuse.com.vn/`,
    '/oms/': `https://tmp-sit.fuse.com.vn/`,
    '/bpm/': `https://tmp-sit.fuse.com.vn/`,
    // '/message/': `https://tmp-sit.fuse.com.vn/`,
    // '/sys/': `https://tmp-sit.fuse.com.vn/`,
    '/dps/': `https://tmp-sit.fuse.com.vn/`,
    // '/pfm/': `https://tmp-sit.fuse.com.vn/`,
    '/boss/': `https://boss-sit.fuse.com.vn/api/`
  },

  uat: {
    '/sso/': `https://sso-uat.fuse.${hostSuffix}/api`,
    '/auth/': `https://sso-uat.fuse.${hostSuffix}/api`,
    '/pro/': `https://ptr-uat.fuse.${hostSuffix}/api`,
    '/local/': `https://ptr-uat.fuse.${hostSuffix}/api`,
    '/claim/': `https://ptr-uat.fuse.${hostSuffix}/api`,
    // '/oss/': `https://tmp-uat.fuse.${hostSuffix}/`,
    '/oms/': `https://tmp-uat.fuse.${hostSuffix}/`,
    '/bpm/': `https://tmp-uat.fuse.${hostSuffix}/`,
    // '/message/': `https://tmp-uat.fuse.${hostSuffix}/`,
    // '/sys/': `https://tmp-uat.fuse.${hostSuffix}/`,
    '/dps/': `https://tmp-uat.fuse.${hostSuffix}/`,
    '/pfm/': `https://ptr-uat.fuse.${hostSuffix}/api`,
    '/boss/': `https://boss-uat.fuse.${hostSuffix}/api/`
  },
  prod: {
    '/sso/': `https://sso.fuse.${hostSuffix}/api`,
    '/auth/': `https://sso.fuse.${hostSuffix}/api`,
    '/pro/': `https://ptr.fuse.${hostSuffix}/api`,
    '/local/': `https://ptr.fuse.${hostSuffix}/api`,
    '/claim/': `https://ptr.fuse.${hostSuffix}/api`,
    // '/oss/': `https://tmp.fuse.${hostSuffix}/`,
    '/oms/': `https://tmp.fuse.${hostSuffix}/`,
    '/bpm/': `https://tmp.fuse.${hostSuffix}/`,
    // '/message/': `https://tmp.fuse.${hostSuffix}/`,
    // '/sys/': `https://tmp.fuse.${hostSuffix}/`,
    '/dps/': `https://tmp.fuse.${hostSuffix}/`,
    '/pfm/': `https://ptr.fuse.${hostSuffix}/api`,
    '/boss/': `https://boss.fuse.${hostSuffix}/api`
  }
}
SERVICE_BASE_URL_MAP.development = {
  ...SERVICE_BASE_URL_MAP.uat
  //   ...{
  //   '/oss/': `https://tmp-dev.fusenano.com/`,
  //   '/message/': `https://tmp-dev.fusenano.com/`,
  //   '/sys/': `https://tmp-dev.fusenano.com/`,
  //   '/pfm/': `https://tmp-dev.fusenano.com/`
  // }
}

export const ORIGIN_BASE_URL = VUE_APP_ELECTRON_ORIGIN_BASE_URL || VUE_APP_BASE_API

// 根据后台服务获取对应的api域名
export const transformConfig = (config = {}) => {
  const { url } = config

  if (!url) {
    return config
  }

  const currEnvServiceBaseURLMap = SERVICE_BASE_URL_MAP[VUE_APP_ENV]
  if (!currEnvServiceBaseURLMap) {
    return config
  }

  for (const key in currEnvServiceBaseURLMap) {
    const baseUrl = currEnvServiceBaseURLMap[key]

    // FIXME: 这是一个临时判断
    // 目前uat环境还未做服务拆分，所以不需要做域名转换处理
    const isReplaceService = ['uat', 'prod', 'development', 'dev', 'sit'].includes(VUE_APP_ENV)
    const needReplace = NEED_REPLACE_SERVICE.includes(key) && isReplaceService

    if (url.startsWith(key)) {
      return {
        baseURL: baseUrl,
        // 因为有一些服务的真正接口地址不包含服务名称
        // 所以需要置换为空
        url: needReplace ? url.replace(key, '/') : url
      }
    }
  }

  return config
}

// 根据相对接口路径获取完整的api地址
export const getServiceUrlByPath = (path) => {
  const { baseURL = '', url } = transformConfig({ url: path })

  return `${baseURL}${url}`
}

// 获取单个服务的api基础域名
export const getOssBaseURL = () => {
  if (IS_DEV) {
    return ORIGIN_BASE_URL
  }

  const currEnvServiceBaseURLMap = SERVICE_BASE_URL_MAP[VUE_APP_ENV]

  return currEnvServiceBaseURLMap[`/pro/`] || ORIGIN_BASE_URL
}

export const regionLanMap = {
  vn: 'vi_VN',
  vi: 'vi_VN',
  en: 'en_US',
  th: 'th_TH'
}
