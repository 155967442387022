import axios from 'axios'
import { ORIGIN_BASE_URL, transformConfig, regionLanMap } from '@/config'

axios.defaults.baseURL = ORIGIN_BASE_URL

function getUrlParams(url) {
  const urlStr = url.split('?')[1]
  const urlSearchParams = new URLSearchParams(urlStr)
  const result = Object.fromEntries(urlSearchParams.entries())
  return result
}

const urlParams = getUrlParams(location.href)
const { region = 'vn' } = urlParams
const baseHeader = {
  language: regionLanMap[region] || regionLanMap['en']
}

const Request = async (params = {}, url, method, header = {}) => {
  // 补充规范 language 字段
  if (header.language && regionLanMap[header.language]) {
    baseHeader.language = regionLanMap[header.language]
  }
  const res = await new Promise((resolve, reject) => {
    // 和app通信获取基本信息

    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          appCode: 'IDP_FUSE_PRO',
          ...header,
          ...baseHeader
        },
        method: method || 'GET',
        url: url.replace(/^\/?vn\//, '/')
      }

      if (config.method.toUpperCase() == 'GET') {
        config.params = params
      } else {
        config.data = params
      }

      const tConfig = transformConfig(config)
      config.baseURL = tConfig.baseURL
      config.url = tConfig.url

      axios(config).then(function (response) {
        resolve(response.data)
        if (response.error) {
          reject(response)
        }
      })
    } catch (error) {
      reject(error)
    }
  }).catch((e) => {
    return e.data
  })
  return res
}

export default Request
