/**
 * @Author:       huangjiaquan
 * @Date:         2023-06-12 14:51
 * @LastEditTime: 2023-06-12 14:51
 * @LastEditors:  huangjiaquan
 * @FilePath:     scm-web/src/views/test/utils
 */
/**
 * @Author:       huangjiaquan
 * @Date:         2023-06-12 14:32
 * @LastEditTime: 2023-06-12 14:32
 * @LastEditors:  huangjiaquan
 * @FilePath:     scm-web/src/views/test
 */
import { inputFormatter } from './inputFormatter'
// import { Format } from './format'

// function bindFormatFunc(el, binding, vnode) {
//   let value = vnode.text
//   if (vnode.data && vnode.data.domProps && vnode.data.domProps.innerHTML) {
//     value = vnode.data.domProps.innerHTML
//   }
//   if (vnode.children && vnode.children.length === 1) {
//     value = vnode.children[0].text.replace(/^\s(.*)\s$/, '$1')
//   }
//   el.innerHTML = Format(binding.value, value).join('')
// }
// export const format = {
//   // inserted: bindFormatFunc,
//   update: bindFormatFunc
// }

// 千分位指令
export const thousands = {
  inserted: inputFormatter({
    // 格式化函数
    formatter(num, countrySetting) {
      num = num.toString()
      const num1 = num.split(`${countrySetting.thousands[1]}`)[0]
      const num2 = num.split(`${countrySetting.thousands[1]}`)[1]
      const c = num1.toString().replace(/(\d)(?=(?:\d{3})+$)/g, `$1${countrySetting.thousands[0]}`)
      return num.toString().indexOf(`${countrySetting.thousands[1]}`) !== -1 ? c + `${countrySetting.thousands[1]}` + num2 : c
    },
    // 解析函数
    parser(val, countrySetting) {
      const step_1_reg = new RegExp(`\\${countrySetting.thousands[0]}`, 'g')
      val = val.toString().replace(step_1_reg, '')
      return val.indexOf(`${countrySetting.thousands[1]}`) === val.length - 1
        ? val
        : isNaN(Number(val))
          ? val
          : Number(val)
    },
    isNumber: true
  })
}

// 银行卡号
export const card = {
  inserted: inputFormatter({
    // 格式化函数
    formatter(num) {
      num = num.replace(/[^\d^\ ]+/g, '')
      if (/\S{5}/.test(num)) {
        return num
          .replace(/\s/g, '')
          .replace(/(.{4})/g, '$1 ')
          .replace(/(^\s*)|(\s*$)/g, '')
      }
      return num
    },
    // 解析函数
    parser(val) {
      val = val.toString()
        .replace(/\s+/g, '')
      return val
    }
  })
}

