import { Session } from './storage'
// import Dao from './dao'
import { dispatchAction } from './dispatch'
import store from '../store'
import { Locale } from 'vant'
import i18n from '@/i18n/i18n'
import { isInApp } from '@/utils/dispatch'
import { switchNewCoreLogic } from '@/api'

const sessionAppInfo = Session.get('appInfo') || {}
export const appInfo = { ...sessionAppInfo }

const { hash } = location

const registerPaths = ['#/register', '/register/success', '/first-login', '/desktop/downloads', '/register/invalidReferralCode']
const IS_REGISTER = registerPaths.some(path => hash.includes(path))

const IS_PAYMENT = hash.includes('#/payment')

export const initApp = (getTokenCallback = () => {}) => {
  // 每次初始化先清除缓存
  // Dao.clear()

  if (!isInApp) {
    Object.assign(appInfo, {
      language: 'en_US', // 接口头字段参数
      lan: 'en',
      region: 'TH',
      currency: 'VND',
      partnerFullName: 'Full Name',
      isOIC: 0, // 0: false; 1: true
      isTPB: 0, // 0: false; 1: true
      distName: '', // Orient-Thai Paiboon Insurance PLC.
      idCardStatus: 1,
      accountType: 3,

      // TH
      token: 'eyJhbGciOiJIUzI1NiIsInppcCI6IkRFRiJ9.eNpcUUFuwjAQ_ErlM5HiONgJNyitmkuLCj30ZG1tB0wTJ0qctgjx9y6OKig-WTuzM7uzR-KNA-el1WRG4zgWQkxIP3yQGeE8zilljCUJIxPStMYFGnnfurlO9otF9o31oTed7JrKINgjejxhUVVga6maugV3kMO5zQ1VdQuoRps_xB9a_FOkNIPz3aEYJ6KMswmpG_VZXERGxn3oJpsnNAQVitJBfa6ti83dCjrvTIdgr5qzNiGj0HPgjFLmp0UbEWec02mOu0Pb_oWRovuEWPCBIRifBsbeW9TShhsBNI0Ey5IoZSlEUJYmyrjKWSmEzjNORrlxS1IsV_Lxbf0gV68viHxBZTV4I1vooO7_B7SzGOpVbLaf69o6Miuh6s1l3XCPm0OFgwQgiREaH0tpjpg2ytZQLe3WerRMrsbYgdMVxhUcT78AAAD__w.lXnK28bJHt4vwsbtzQBB_0YhfKF_fUEA8eAeOl_aE5E'

      // VN
      // token: 'eyJhbGciOiJIUzI1NiIsInppcCI6IkRFRiJ9.eNpcUV1PwyAU_S88twn0g3V7M06TvcxlRl8JA-pQoE2harPsv3sL2XTeJ3LO4Zz7cUJBOe4C0xKtCMaYUpohPx7QCjUVKUsChSnKUNcrF1VoP0y0bOyuXtIvIEavBjZ0RgHrgT6dARSGa8tEZ3vuJjbO_9xozH9CdFJdmDD18CYg6UYXhmmTOiIlLTJkO_Gx-TVJivv4G71uIZCLCDLH7YwF5UPq24tu9kUomWwjn2zUdw8RC7ygBa5rSOZ9f1lEBckZ0jxEBS2LKiregwavqqb1UhxkvmiaIq-UFHmDW5I3qlXFsq0OLZmjZ7s0Idqsd-zx5fmB7fZPwHxyoyUPivV84NbfLueoYaF_Vqb9nbTaoVXLjVe_o8Zj3B4p3iLiRQGnxKlIQwiQUgltuVnrNx2ukddOjtxJo4aEn38AAAD__w.Y0_-xBN5jEqqSNQIaoG89qVmbYhsh_B9bULYXhpQeRU'
    }) // 本地开发环境不会执行下面的 dispatchAction 方法  所以这里可以模拟初始化 appInfo 数据

    // 越南 customer pay
    if (IS_PAYMENT) {
      Object.assign(appInfo, {
        language: 'en_US', // 接口头字段参数
        lan: 'en',
        region: 'VN',
        currency: 'VND',
        partnerFullName: 'Full Name',
        isOIC: 0, // 0: false; 1: true
        isTPB: 0, // 0: false; 1: true
        distName: '', // Orient-Thai Paiboon Insurance PLC.
        idCardStatus: 1,
        accountType: 3,
        token: 'eyJhbGciOiJIUzI1NiIsInppcCI6IkRFRiJ9.eNpUUEFugzAQ_MueQTLgEOBWlVZKDzRK1V7RxjiJG2MsbKpGUf5eY6s03ePszM7sXMFyhcq2ooMqIYTkeR6BmfZQQUGTNCv8QARfKEWHlrcaR-wNVGqSMoJBc-XFQGmtz81RNbvM0SfDx3YcJHdLR4brzYFMouhbNvQa1aWdZlm4EhYn4TR3qDAPXS8UVAeUhkdgL5q7lI4-TMqOl03InGR5GkE_sPNmkSLznJDs_yM-mcdT9y0Jk6yKEpbDj0PnjOCjgb9LCvsZe-HGCIZuYdgwxwEI3o3fB_eOM9GjrMVR2KUp_q1d3DXJs5TQsnCXtf6tnboEdxWfUHWSj0sPaL1yRVLqlZ9WOOM849mecYxXWYExLUsW42FdxklKSZqUZE_Y_NNsw8JDm3rbPr-_PbXb3SvcfgAAAP__.z6iaF_QHO0LLa4RjwZcdkaFLLLINv4XhjxR1NruPMtA'
      }) // 本地开发环境不会执行下面的 dispatchAction 方法  所以这里可以模拟初始化 appInfo 数据
    }

    Session.set('appInfo', appInfo)
    Locale.use(appInfo.lan)
    i18n.locale = appInfo.lan
    store.dispatch('base/changeCurrency', appInfo.currency)
    store.dispatch('base/changeRegion', appInfo.region)

    // 新核心功能开关请求
    // 注册页面不需要发送请求
    // if (!IS_REGISTER && !IS_PAYMENT) {
    //   switchNewCoreLogic({}).then(res => {
    //     const { enabled, supportRebate, supportCoupon, supportBillingPayment } = res || {}

    //     appInfo.enabled = enabled
    //     appInfo.supportRebate = supportRebate
    //     appInfo.supportCoupon = supportCoupon
    //     // 是否支持走新收银台逻辑
    //     appInfo.supportBillingPayment = supportBillingPayment

    //     Session.set('appInfo', appInfo)
    //   })
    // }

    if (appInfo.token && (typeof getTokenCallback === 'function')) {
      getTokenCallback()
    }
  } else {
    dispatchAction({
      method: 'getToken',
      callback: (result) => {
        if (!result) return
        appInfo.token = result.token || ''

        Session.set('appInfo', appInfo)

        // 新核心功能开关请求
        // 注册页面不需要发送请求
        if (!IS_REGISTER && !IS_PAYMENT) {
          switchNewCoreLogic({}).then(res => {
            const { enabled, supportRebate, supportCoupon, supportBillingPayment } = res || {}

            appInfo.enabled = enabled
            appInfo.supportRebate = supportRebate
            appInfo.supportCoupon = supportCoupon
            // 是否支持走新收银台逻辑
            appInfo.supportBillingPayment = supportBillingPayment

            Session.set('appInfo', appInfo)
          })
        }

        if (appInfo.token && (typeof getTokenCallback === 'function')) {
          getTokenCallback()
        }
      }
    })

    // 初始化的时候和app通信获取服务器时间
    dispatchAction({
      method: 'getTimeStamp',
      params: {},
      callback: (res) => {
        if (!res) return
        const timeStamp = res.timeStamp.replace(',', '')

        Session.set('serverDate', new Date(timeStamp))
      }
    })

    dispatchAction({
      method: 'getBaseConfig',
      callback: (result) => {
        if (!result) return
        const mode = process.env.VUE_APP_MODE
        const reg = /\.th$/
        if (reg.test(mode)) {
          result = { ...result, region: 'VN', currency: 'VND', lan: 'vi' }
        }
        // 泰国针对 currency 的特殊处理
        if (result.region === 'TH') {
          result.currency = 'THB'
        }
        Object.assign(appInfo, {
          ...result,
          lan: result.lan || 'en',
          agentUid: result.accountId || '',
          region: result.region || 'VN',
          currency: result.currency || 'VND',
          zone: result.zone || 7,
          // 身份证号码认证状态，-2: 未绑定 -1:未审核 0:待审核 1:审核通过 2:审核失败  3 重新检测
          idCardAuthenticationStatus: result.idCardAuthenticationStatus === undefined ? 1 : result.idCardAuthenticationStatus
        })

        store.dispatch('base/changeLang', appInfo.lan)
        store.dispatch('base/changeCurrency', appInfo.currency)
        store.dispatch('base/changeTimeZone', appInfo.zone)
        store.dispatch('base/changeRegion', appInfo.region)
        Session.set('appInfo', appInfo)

        Locale.use(appInfo.lan)
        i18n.locale = appInfo.lan
      }
    })
  }
}

